'use client';

import Sentry from '@/components/Sentry';
import {
  Box,
  Flex,
  VStack,
  extendTheme,
} from '@medsimples/design-system/src/chakra';
import { Poppins } from 'next/font/google';
import type { ReactNode } from 'react';
import { useTenantConfig } from './providers/tenant_provider';
import { ProvidersClient } from './providers_client';

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export default function RootLayoutClient({
  children,
  theme,
  idToken,
}: {
  children: ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  theme: any;
  idToken: string;
}) {
  const extendedTheme = extendTheme({ theme });
  const config = useTenantConfig();

  return (
    <html lang='pt-BR' suppressHydrationWarning>
      <title>{config?.siteTitle}</title>
      <link rel='icon' type='image/*' href={config.favicon} />
      <body>
        <ProvidersClient theme={extendedTheme}>
          <main className={poppins.className}>
            <Flex
              h='100vh'
              flexDirection='column'
              justifyContent='start'
              alignItems='center'
            >
              <VStack maxW='400px' paddingY={9} paddingX={7} spacing={10}>
                <Box>
                  <img
                    style={{
                      minHeight: '90px',
                      maxHeight: '100px',
                      maxWidth: '250px',
                    }}
                    data-testid='logo'
                    src={config.logo}
                    alt='logo'
                  />
                </Box>
                {children}
              </VStack>
            </Flex>
            <Sentry idToken={idToken} />
          </main>
        </ProvidersClient>
      </body>
    </html>
  );
}
